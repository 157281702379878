<template>
  <div class="destination d-flex flex-column flex-1 position-relative">
    <template v-if="loading">
      <transition appear name="fade" mode="out-in">
        <Spinner fill_parent />
      </transition>
    </template>
    <template v-else>
      <div class="py-2 d-flex flex-column gap-2" :class="{ 'ps-3': indent }">
        <div class="d-flex align-items-end justify-content-between gap-5">
          <h6 class="mb-2 text-nowrap">Target Type</h6>
          <div class="p-2 form-control border" v-if="view">
            {{ types?.find((t) => t.value === newData.target_type).text }}
          </div>
          <select
            v-else
            :disabled="disabled"
            class="form-select"
            :value="newData.target_type"
            @input="update({ target_type: $event.target?.value })"
          >
            <option v-for="{ text, value } in types" :value="value">{{ text }}</option>
          </select>
        </div>
        <div class="d-flex align-items-end justify-content-between gap-5">
          <h6 class="mb-2 text-nowrap">Target Mode</h6>
          <div class="p-2 form-control border" v-if="view">
            {{ modes?.find((t) => t.value === newData.target_mode).text }}
          </div>
          <select
            v-else
            :disabled="disabled"
            class="form-select"
            :value="newData.target_mode"
            @input="update({ target_mode: $event.target?.value })"
          >
            <option v-for="{ text, value } in modes" :value="value">{{ text }}</option>
          </select>
        </div>
        <div class="d-flex align-items-end justify-content-between gap-5">
          <h6 class="mb-2 text-nowrap">Target ID</h6>
          <div class="p-2 form-control border" v-if="view">
            <template v-if="newData.target_type === 'place' || newData.target_type === 'route'">
              <localised-value :value="newData.target_id" />
            </template>
            <template v-if="newData.target_type === 'service'">
              <p>Coming Soon</p>
            </template>
            <template v-else-if="['poi', 'event'].includes(newData.target_type)">
              <localised-value :value="options?.find((t) => t.id === parseInt(newData.target_id))?.title" />
            </template>
            <template v-else-if="['page'].includes(newData.target_type)">
              <localised-value :value="options?.find((t) => t.id === parseInt(newData.target_id))?.key" />
            </template>
          </div>
          <template v-else>
            <template v-if="newData.target_type === 'place' || newData.target_type === 'route'">
              <text-input
                :disabled="disabled"
                class="border"
                :value="newData.target_id"
                @change="update({ target_id: $event })"
              />
            </template>
            <template v-if="newData.target_type === 'service'">
              <p>Coming Soon</p>
            </template>
            <template v-else-if="['poi', 'event', 'page'].includes(newData.target_type)">
              <custom-select
                :disabled="disabled"
                :placeholder="`Select ${newData.target_type.toUpperCase()}`"
                :options="options"
                :selected="[parseInt(newData.target_id)]"
                @selected="update({ target_id: `${$event.id}` })"
              >
                <template #default="{ option }">
                  <localised-value :value="option?.title ?? option?.key" />
                </template>
              </custom-select>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import { Spinner } from '@avriopolis/common/components'
import CustomSelect from '@/components/inputs/CustomSelect.vue'
import project from '@/store/project'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import TextInput from '@/components/inputs/TextInput.vue'

import axios from '@/api'

export default defineComponent({
  name: 'Destination',
  emits: ['update'],
  components: {
    Spinner,
    CustomSelect,
    LocalisedValue,
    TextInput,
  },
  props: {
    indent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<{
        target_type: String
        target_mode: String
        target_id: String
      }>,
      default: {
        target_type: null,
        target_mode: 'blank',
        target_id: null,
      },
    },
  },
  setup(props, { emit }) {
    const loading = ref(true)
    const types = ref<{ text: string; value: string }[]>()
    const modes = ref<{ text: string; value: string }[]>()
    const points_of_interest = ref<PointOfInterest[]>()
    const announcements = ref<Announcement[]>()
    const pages = ref<Component[]>()

    const newData = reactive({
      target_type: props.value?.target_type,
      target_mode: props.value?.target_mode,
      target_id: `${props.value?.target_id}`,
    })

    const fetchData = async () => {
      const [type, mode, pois, anns, components] = (
        await Promise.all([
          axios.get(`/fields/menus/target_type`),
          axios.get(`/fields/menus/target_mode`),
          axios.get(`/items/points_of_interest/?fields=id,title&filter[projects_id]=${project.active?.id}`),
          axios.get(`/items/announcements/?fields=id,title&filter[projects_id]=${project.active?.id}`),
          axios.get(`/items/components/?fields=id,title,metadata&filter[projects_id]=${project.active?.id}`),
        ])
      ).map((r) => r.data?.data)

      types.value = type?.meta?.options?.choices
      modes.value = mode?.meta?.options?.choices
      points_of_interest.value = pois
      announcements.value = anns
      pages.value = components.map((c: Component) => ({ ...c, title: c.metadata.title ?? c.key }))

      loading.value = false
    }

    async function update(data?: any) {
      if (props.value) {
        Object.assign(newData, data)
        emit('update', newData)
        console.log(newData)
      }
    }

    const options = computed(() => {
      switch (newData.target_type) {
        case 'poi':
          return points_of_interest.value?.filter((c) => !!c.title)
        case 'event':
          return announcements.value?.filter((c) => !!c.title)
        case 'service':
          return points_of_interest.value?.filter((c) => !!c.title)
        case 'page':
          return pages.value?.filter((c) => !!c.title)
      }
    })

    onMounted(() => {
      fetchData()
    })

    return {
      loading,
      types,
      modes,
      points_of_interest,
      newData,
      options,
      update,
    }
  },
})
</script>

<style lang="scss" scoped>
.destination {
  min-height: 64px;
}
</style>
