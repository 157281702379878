<template>
  <div class="flex-grow-1 btn-group border rounded-3 d-flex align-items-center justify-content-end" :class="{ icons }">
    <button
      v-for="(_locale, i) in filteredLocales"
      type="button"
      class="btn p-1 language"
      :class="`${locale === _locale ? 'btn-primary' : 'btn-light'}`"
      :key="i"
      @click="$emit('locale', _locale)"
    >
      <img :src="flags[_locale]" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import project from '@/store/project'

import i18n from '@avriopolis/common/i18n'
import enUS from '@avriopolis/common/assets/flags/en-US.svg'
import frFR from '@avriopolis/common/assets/flags/fr-FR.svg'
import nlNL from '@avriopolis/common/assets/flags/nl-NL.svg'
import itIT from '@avriopolis/common/assets/flags/it-IT.svg'
import esES from '@avriopolis/common/assets/flags/es-ES.svg'

export default defineComponent({
  name: 'LanguageSelector',
  emits: ['locale'],
  props: {
    locales: {
      type: Array as PropType<Array<string>>,
      default: i18n.global.availableLocales,
    },
    locale: {
      type: String,
      required: true,
    },
    icons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const flags: { [key: string]: string } = {
      'en-US': enUS,
      'fr-FR': frFR,
      'nl-NL': nlNL,
      'it-IT': itIT,
      'es-ES': esES
    }

    const filteredLocales = computed(() => {
      return props.locales.filter((l) => project.active?.languages.includes(l))
    })

    return { flags, filteredLocales }
  },
})
</script>

<style lang="scss" scoped>
.language img {
  height: 16px;
}

.language.btn-light {
  img {
    opacity: 0.5;
    filter: grayscale(0.8);
  }
}

.icons {
  border: none !important;
  gap: 6px;

  .language {
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    flex-grow: 0;
  }

  .language,
  img {
    width: 32px;
    height: 22px;
  }
}
</style>
