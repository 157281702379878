<template>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <transition appear name="fade">
      <div class="content d-flex flex-column flex-grow-1 position-relative p-0 h-100 overflow-hidden">
        <div v-if="showFilters && state.meta" style="z-index: 2" class="card card-rounded">
          <div class="card-body">
            <Filter :meta="state.meta" @filter="updateFilters" />
          </div>
        </div>
        <button
          v-if="canUpload"
          class="btn btn-outline-secondary btn-sm d-flex mt-4 d-sm-none"
          data-bs-toggle="modal"
          data-bs-target="#upload"
        >
          <span class="material-icons me-2">upload</span>
          <span class="fs-6">Upload</span>
        </button>
        <div v-if="state.media" class="media-viewer-wrapper gap-4 mb-4 mt-4 mt-sm-0 pt-sm-4">
          <div class="h-100" data-simplebar>
            <div v-if="canUpload" class="media d-flex d-none d-sm-flex">
              <button
                class="btn btn-outline-secondary btn-sm d-flex flex-grow-1 w-100"
                data-bs-toggle="modal"
                data-bs-target="#upload"
              >
                <span class="material-icons me-2">upload</span>
                <span class="fs-6">Upload</span>
              </button>
            </div>
            <div v-if="!canUpload && state.media.length == 0" class="d-flex d-none d-sm-flex">
              <p>No media found.</p>
            </div>
            <div
              v-for="media of state.media"
              :key="media.id"
              class="media"
              @click="selectImage(media)"
              :class="{ active: state.active.includes(media), locked: media.locked }"
            >
              <div class="img-wrapper">
                <template v-if="media.files_id.type.indexOf('video') !== -1">
                  <video onmouseover="this.play()" onmouseout="this.pause();this.currentTime=0;">
                    <source :src="`${mediaBase}/assets/${media.files_id.id}`" :type="media.files_id.type" />
                    Your browser does not support the video tag.
                  </video>
                </template>
                <img v-else :src="`${mediaBase}/assets/${media.files_id.id}?key=gallery`" />
              </div>
              <span v-if="media.locked" class="position-absolute d-flex top-0 end-0 badge bg-danger">
                <span class="material-icons">lock</span>
              </span>
              <span v-if="canSelect" class="position-absolute d-flex top-0 start-0 badge bg-dark m-1">
                <span v-if="state.active.includes(media)" class="material-icons text-white">check_box</span>
                <span v-else class="material-icons text-muted">check_box_outline_blank</span>
              </span>
            </div>
          </div>
          <progress-bar v-if="state.loading" />
        </div>
        <Pagination :page="state.query.page" :items="state.items" @page="updatePage" :perPage="state.query.limit" />
      </div>
    </transition>

    <div v-if="canEdit" ref="offcanvasRef" class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas">
      <div class="position-absolute top-0 end-0 p-3">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <h5 class="p-3 m-0">Media</h5>
      <MediaEditor v-if="state.active.length > 0" :media="state.active[0]" @update="update" />
    </div>

    <teleport to="#modals">
      <media-uploader
        :single="single"
        :options="{ ...query?.filter, ...state?.query?.filter }"
        @mediaCreated="updateMedia"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, PropType, onUnmounted } from 'vue'
import axios from '@/api'
import { debounce } from 'lodash'
import { Offcanvas } from 'bootstrap'

import { PageHeader, Spinner } from '@avriopolis/common/components'
import { hasChanges, processChanges } from '@/store/changes'

import ProgressBar from '@/components/misc/ProgressBar.vue'
import MediaUploader from '@/components/media/MediaUploader.vue'
import MediaEditor from '@/components/media/MediaEditor.vue'
import Filter from '@/components/misc/Filter.vue'
import Pagination from '@/components/pages/Pagination.vue'
import project from '@/store/project'

import { merge } from 'lodash'

type MediaStore = {
  loading: boolean
  media?: Media[]
  query: any
  meta: any
  items: number
  active: Media[]
}

export default defineComponent({
  name: 'MediaViewer',
  props: {
    query: Object,
    canUpload: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    media: {
      type: Array as PropType<Media[]>,
    },
    active: {
      type: Array as PropType<Media[]>,
      default: [],
    },
  },
  emits: ['loading', 'selected'],
  components: {
    PageHeader,
    Filter,
    Spinner,
    Pagination,
    ProgressBar,
    MediaUploader,
    MediaEditor,
  },
  setup(props, { emit }) {
    const offcanvasRef = ref(),
      offcanvas = ref()

    const state = reactive<MediaStore>({
      loading: false,
      query: {
        limit: 15,
        fields: '*.*',
        meta: '*',
        sort: ['-date_created', '-date_updated'],
        page: 1,
        filter: { projects_id: project?.active?.id },
      },
      meta: undefined,
      media: props.media,
      items: 1,
      active: [],
    })

    function selectImage(media: Media) {
      if (!props.canSelect) return
      const index = state.active.indexOf(media) ?? -1
      if (index !== -1) {
        state.active.splice(index, 1)
      } else {
        if (props.single || props.canEdit) {
          state.active = [media]
        } else {
          state.active.push(media)
        }
      }

      emit(
        'selected',
        state.active.map((c) => c.files_id),
      )
      if (props.canEdit) offcanvas.value.show()
    }

    function updateFilters(filters: any) {
      state.query.filter = merge({ projects_id: project?.active?.id }, filters)
      updateMedia()
    }

    function updatePage(page: number) {
      state.query.page = page
      updateMedia()
    }

    const update = debounce(async function update() {
      updateMedia()
      updateMeta()
    }, 1000)

    function setLoading(global: boolean, value: boolean) {
      if (global) {
        emit('loading', value)
      } else {
        state.loading = value
      }
    }

    async function updateMedia() {
      setLoading(false, true)
      await fetchData()
      setLoading(false, false)
    }

    async function updateMeta(initial = false) {
      setLoading(initial, true)
      try {
        await fetchMeta()
        await updateMedia()
      } catch (e) {}
      setLoading(initial, false)
    }

    async function fetchMeta() {
      try {
        const filter = merge({ projects_id: project?.active?.id }, props?.query?.filter ?? {})
        const meta_query = new URLSearchParams({ filter: JSON.stringify(filter) }).toString()
        const meta_data = await axios.get(`/custom/meta/media?${meta_query}`)
        state.meta = meta_data?.data ?? meta_data
      } catch (e) {}
    }

    async function fetchData() {
      try {
        const query = merge(
          state.query,
          props.query,
          Array.isArray(props.active) && props.active.length > 0
            ? { filter: { files_id: { _nin: props.active } } }
            : {},
        )
        const media_query = new URLSearchParams({ ...query, filter: JSON.stringify(query.filter) }).toString()
        const mediaData = await axios.get(`/items/media?${media_query}`)
        state.media = mediaData?.data?.data
        state.items = mediaData?.data?.meta?.filter_count
      } catch (e) {
        state.media = []
        state.items = 0
      }
    }

    onMounted(() => {
      if (offcanvasRef.value) {
        offcanvas.value = new Offcanvas(offcanvasRef.value)
        offcanvasRef.value.addEventListener('hide.bs.offcanvas', async (e: any) => {
          if (hasChanges()) {
            e.preventDefault()
            if (await processChanges(false)) {
              offcanvas.value.hide()
              state.active = []
            }
          }
        })
      }
    })

    if (!props.media) updateMeta(true)

    return {
      state,
      selectImage,
      offcanvasRef,
      updateFilters,
      updatePage,
      updateMedia,
      update,
      mediaBase: import.meta.env.VITE_API_URL,
    }
  },
})
</script>

<style lang="scss" scoped>
.media-viewer-wrapper {
  //display: grid;
  //grid-template-columns: auto;

  flex: 1;
  overflow: hidden;

  ::v-deep(.simplebar-content) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
  }

  .media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #ffffff;

    width: 280px;
    height: 280px;
    overflow: hidden;

    opacity: 0.75;
    filter: grayscale(0.75);
    border: 5px solid white;

    .img-wrapper {
      width: 100%;
      height: 100%;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        object-fit: contain;
      }
    }

    &.active,
    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    &.active {
      border: 5px solid white;
      box-shadow: 0 0 3px black;
    }
  }

  @media (min-width: 411px) {
    //grid-template-columns: auto auto;
    .media {
      width: 180px;
      height: 180px;
      min-width: 180px;
      min-height: 180px;
    }
  }
  /*@media (min-width: 576px) {
    grid-template-columns: auto auto;
  }
  @media (min-width: 628px) {
    grid-template-columns: auto auto auto;
  }
  @media (min-width: 854px) {
    grid-template-columns: auto auto auto auto;
  }
  @media (min-width: 992px) {
    grid-template-columns: auto auto auto;
  }
  @media (min-width: 1080px) {
    grid-template-columns: auto auto auto auto;
  }
  @media (min-width: 1300px) {
    grid-template-columns: auto auto auto auto auto;
  }*/
}
</style>
