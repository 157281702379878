export const COMPONENTS = [
  {
    key: 'id',
    type: 'basic',
    text: '#',
    sortable: true,
  },
  {
    key: 'template_id',
    text: 'Template ID',
    type: 'func',
    sortable: false,
    visible: true,
    _get: (e: any) => {
      return e.template_id?.id
    },
  },
  {
    key: 'template_name',
    text: 'Template Name',
    type: 'func',
    sortable: false,
    visible: true,
    _get: (e: any) => {
      return e.template_id?.key
    },
  },
  {
    key: 'key',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'status',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'date_created',
    type: 'date',
    text: 'Date Created',
    sortable: true,
    visible: true,
  },
  {
    key: 'date_updated',
    type: 'date',
    text: 'Date Updated',
    sortable: true,
    visible: false,
  },
]

export const POINTS_OF_INTEREST = [
  {
    key: 'id',
    type: 'basic',
    text: '#',
    sortable: true,
  },
  {
    key: 'status',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'poi_status',
    type: 'basic',
    text: 'POI Status',
    sortable: true,
    visible: false,
  },
  {
    key: 'slug',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'source',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'title',
    type: 'localised-value',
  },
  {
    key: 'date_created',
    type: 'date',
    text: 'Date Created',
    sortable: true,
    visible: false,
  },
  {
    key: 'date_updated',
    type: 'date',
    text: 'Date Updated',
    sortable: true,
    visible: false,
  },
  {
    key: 'external_id',
    type: 'basic',
    text: 'exnternal id',
    sortable: true,
    visible: false,
  },
  {
    key: 'tags',
    type: 'array',
    visible: false,
  },
]

export const ANNOUNCEMENTS = [
  {
    key: 'id',
    type: 'basic',
    text: '#',
    sortable: true,
  },
  {
    key: 'type',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'status',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'slug',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'source',
    type: 'basic',
    sortable: true,
  },
  {
    key: 'title',
    type: 'localised-value',
  },
  {
    key: 'date_created',
    type: 'date',
    text: 'Date Created',
    sortable: true,
    visible: false,
  },
  {
    key: 'date_updated',
    type: 'date',
    text: 'Date Updated',
    sortable: true,
    visible: false,
  },
  {
    key: 'external_id',
    type: 'basic',
    text: 'exnternal id',
    sortable: true,
    visible: false,
  },
  {
    key: 'tags',
    type: 'array',
    visible: false,
  },
]

export const MEDIA = [
  {
    key: 'id',
    type: 'basic',
    text: '#',
    sortable: true,
  },
]
