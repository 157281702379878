<template>
  <div class="btn-group w-100">
    <button
      :disabled="disabled"
      class="btn btn-light d-flex justify-content-between dropdown-toggle text-capitalize w-100 overflow-hidden gap-2"
      type="button"
      id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div class="d-flex flex-grow-1 gap-2 flex-wrap align-items-center overflow-hidden">
        <template v-if="!selected || selected.length === 0 || !options">
          {{ placeholder }}
        </template>
        <template v-else v-for="(index, i) of selected" :key="i">
          <span class="badge bg-primary">
            <slot :option="options?.find((o) => (o.id ?? o) === index)">{{ index }}</slot>
          </span>
        </template>
      </div>
    </button>
    <ul
      class="dropdown-menu p-2 overflow-auto pb-0"
      aria-labelledby="dropdownMenuClickableInside"
      style="min-width: 100%; max-width: 100%; max-height: 300px"
      v-if="options"
    >
      <li
        v-for="option in options"
        :key="option"
        class="option d-flex justify-content-between px-2 border mb-2 align-items-center"
        :class="{ 'border-primary': selected && selected.includes(option.id ?? option) }"
      >
        <input
          class="input form-check-input"
          type="checkbox"
          value=""
          :id="option.id ?? option"
          @input="$emit('selected', option)"
          :checked="selected && selected.includes(option.id ?? option)"
          style="height: 1.5rem; width: 1.5rem"
        />
        <label
          class="label form-check-label flex-grow-1 text-capitalize text-nowrap text-end"
          :for="option.id ?? option"
        >
          <slot :option="option">{{ option }}</slot>
        </label>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CustomSelect',
  emits: ['selected'],
  props: {
    placeholder: {
      type: String,
    },
    options: {
      type: Array as PropType<Array<any>>,
    },
    selected: {
      type: Array as PropType<Array<Number>>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.badge {
  position: relative;
  top: unset !important;
}

.btn-group {
  min-width: 200px;
  min-height: fit-content;
  flex: 1;

  button {
    border: 1px solid #ced4da;
    color: #212529;
    &:disabled {
      background: #e9ecef;
    }
  }
  .badge {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .option {
    height: 2.5rem;
    gap: 12px;
    .input {
      min-width: 24px;
      min-height: 24px;
    }
    .label {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}
</style>
