<template>
  <div class="flex-grow-1">
    <div class="map-wrapper">
      <map-view :markers="kiosks" overlap data_loaded ref="mapRef" @loaded="loaded" :map_data="map_data" />
    </div>
    <div class="pt-2">
      <label for="customRange1" class="form-label m-0">Radius ({{ radius }}KM)</label>
      <input
        type="range"
        class="form-range"
        id="customRange1"
        min="0.01"
        max="2.5"
        step="0.01"
        :value="radius"
        @input="radius = parseFloat($event.target.value)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { MapView } from '@avriopolis/common/components'
import type { Marker as MapMarker } from '@avriopolis/common/components'

import { createCircle } from '@/utils/misc'
import { computed, defineComponent, PropType, ref, watch, watchEffect } from 'vue'

import { Marker } from 'mapbox-gl'
import project from '@/store/project'
import _ from 'lodash'

export default defineComponent({
  name: 'GEO_Selector',
  emits: ['geo'],
  components: {
    MapView,
  },
  props: {
    kiosks: {
      type: Array as PropType<Array<MapMarker>>,
      default: [],
    },
    geo: {
      type: Object as PropType<{ lat: number; lng: number; radius: number }>,
      default: {},
    },
  },
  setup(props, { emit }) {
    const mapRef = ref<typeof MapView>()
    const center = ref<MapMarker>({ lat: project.active?.lat ?? 0, lng: project.active?.lng ?? 0 })
    const radius = ref<number>(2.5)

    const map_data = computed(() => {
      return {
        center: [project?.active?.lng, project?.active?.lat],
      }
    })

    function clear() {
      if (!mapRef.value) return
      const { mapInstance } = mapRef.value
      const layer = mapInstance.getLayer('circle')
      if (layer) mapInstance.removeLayer('circle')

      const source = mapInstance.getSource('circle')
      if (source) mapInstance.removeSource('circle')
    }

    const updateCircle = _.debounce((emits = true) => {
      if (!mapRef.value || !center.value) return
      const { mapInstance } = mapRef.value
      const { lat, lng } = center.value

      if (emits) {
        emit('geo', { lat, lng, radius: radius.value })
      }

      clear()
      console.log(props.geo, [lng, lat])
      mapInstance.addSource('circle', createCircle([lng, lat], radius.value))
      mapInstance.addLayer({
        id: 'circle',
        type: 'fill',
        source: 'circle',
        layout: {},
        paint: {
          'fill-color': 'blue',
          'fill-opacity': 0.6,
        },
      })
    }, 100)

    function loaded() {
      if (!mapRef.value) return
      const { mapInstance } = mapRef.value

      updateCircle()

      var marker = new Marker({ draggable: true })
        .setLngLat([center.value.lng, center.value.lat])
        .on('drag', () => {
          center.value = undefined
          clear()
        })
        .on('dragend', () => {
          const newCenter = marker.getLngLat()
          if (!center.value || newCenter.lng !== center.value.lng || newCenter.lat !== center.value.lat) {
            center.value = newCenter
            updateCircle()
          }
        })
        .addTo(mapInstance)
    }

    watchEffect(() => {
      if (radius.value >= 0) {
        updateCircle()
      }
    })

    watchEffect(() => {
      if (props.geo) {
        center.value = {
          lat: props.geo.lat ?? project?.active?.lat,
          lng: props.geo.lng ?? project?.active?.lng,
        }
        radius.value = props.geo.radius ?? 2.5
        updateCircle(false)
      }
    })

    return {
      mapRef,
      loaded,
      map_data,
      radius,
    }
  },
})
</script>

<style scoped>
.map-wrapper {
  position: relative;
  max-height: 500px;
  height: 500px;
  overflow: hidden;
}
</style>
