<template>
  <div class="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
    <h3 class="my-0 ms-4">Filters</h3>
    <div class="filters flex-sm-row gap-sm-3" :class="{ [`filters-${filters.length}`]: true }">
      <div class="filter d-sm-flex flex-sm-column align-items-sm-start" v-for="(filter, n) in filters" :key="n">
        <label :for="filter" class="form-label m-0">
          <small>{{ filter.replace(/\..*?$/, '') }}</small>
        </label>
        <select :id="filter" class="form-select form-select-sm" :value="values[filter]" @change="setFilter">
          <option value="">Default</option>
          <option v-for="{ key, value } in options[filter]" :key="key" :value="key">
            <localised-value :value="value"></localised-value>
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import _ from 'lodash'

import LocalisedValue from '@/components/misc/LocalisedValue.vue'

export default defineComponent({
  name: 'LayoutIndex',
  emits: ['filter'],
  props: ['meta', 'datasets'],
  components: { LocalisedValue },
  setup(props, { emit }) {
    const filters = computed(() => Object.keys(props.meta ?? {}))
    const values = reactive<{ [key: string]: any }>({})
    const query = reactive<{ [key: string]: any }>({})
    const options = reactive<{ [key: string]: any }>({})

    function extractDataset(filter: string, id: any) {
      let value = filter.split('.').reduce((p, c, i, a) => {
        if (i === a.length - 1) {
          const item = p.find((a: any) => a.id === parseInt(id))
          return item?.name ?? item?.title ?? 'N/A'
        }
        return p[c]
      }, props.datasets)

      return value
    }

    function updateFilterValues() {
      for (let filter of filters.value) {
        const filterMeta = props.meta[filter]

        switch (filterMeta.type) {
          case 'string':
          case 'array':
            options[filter] = filterMeta.values.map((v: string) => ({ key: v, value: v }))
            break
          case 'nested':
            options[filter] = filterMeta.values.map((v: any) => {
              return { key: v, value: extractDataset(filter, v) }
            })
            break
        }
      }
    }

    const setFilter = (e: any) => {
      const filterName = e.target.id
      if (e.target.value) {
        const filterMeta = props.meta[filterName]
        values[filterName] = e.target.value

        switch (filterMeta.type) {
          case 'string':
            query[filterName] = e.target.value
            break
          case 'array':
            query[filterName] = { _contains: `"${e.target.value}"` }
            break
          case 'nested':
            filterName
              .split('.')
              .reduce(
                (p: { [key: string]: any }, c: string, i: number, a: Array<string>) =>
                  Object.assign(p, { [c]: i === a.length - 1 ? e.target.value : {} }) && p[c],
                query,
              )
            break
        }
      } else {
        delete values[filterName]
        delete query[filterName.replace(/\..*?$/, '')]
      }

      emit('filter', { ...query })
    }

    updateFilterValues()

    watchEffect(() => {
      if (props.meta) {
        updateFilterValues()
      }
    })

    return { filters, values, options, setFilter }
  },
})
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .filter {
    display: grid;
    grid-template-columns: 40% 50%;
    flex-basis: 33%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  &.filters-1,
  &.filters-2,
  &.filters-3 {
    flex-direction: row;
    gap: 1rem;

    .filter {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }

  select,
  .filter {
    text-transform: capitalize;
  }
}
</style>
