import { createApp, createVNode, render } from 'vue'

import '@avriopolis/common/style/index.scss'
import { Spinner } from '@avriopolis/common/components'

import 'simplebar/dist/simplebar.css'

import router from '@/router'
import i18n from '@/i18n'
import App from '@/App.vue'
import { fetchProjects } from '@/store/project'
import Auth from '@/store/auth'

import axios from '@/api'
import Swal from 'sweetalert2'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error?.request)
    console.log('Error:', error)
    if (error?.response?.status === 403) {
      setTimeout(() => {
        Swal.fire({
          title: `You do not have the authorisation to carry out that action.`,
          icon: 'warning',
          confirmButtonColor: '#0d6efd',
        })
      }, 500)
    }
    return error
  },
)

const node = document.querySelector('#app') || document.body

const loadApp = async () => {
  render(createVNode(Spinner), node)

  try {
    await Auth.refresh()
    await fetchProjects()

    const app = createApp(App)
    app.use(router).use(i18n)
    app.mount(node)
  } catch (e) {
    console.log(e)
  }
}

loadApp()
