<template>
  <Table
    :view="view"
    :edit="edit"
    :archive="archive"
    :title="title"
    :headers="headers"
    :rows="rows"
    v-bind="state"
    @sort="updateState({ sort: $event })"
    @view="$emit('view', $event)"
    @edit="$emit('edit', $event)"
    @archive="$emit('archive', $event)"
    @update="update"
  />

  <Pagination :page="state.page" :items="state.items" @page="updateState({ page: $event })" />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue'

import Pagination from '@/components/pages/Pagination.vue'
import Table from '@/components/misc/Table.vue'

export default defineComponent({
  name: 'PaginatedTable',
  props: {
    filters: {
      type: Object,
      default: {},
    },
    headers: {
      type: Array as PropType<Array<String>>,
      default: [],
    },
    rows: {
      type: Array as PropType<Array<any>>,
      default: [],
    },
    title: {
      type: String,
      default: '',
    },
    view: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Pagination,
    Table,
  },
  emits: ['view', 'edit', 'archive', 'update'],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
      page: 1,
      items: 1,
      sort: '',
    })

    function update() {
      state.loading = true
      emit('update', state, (newState: any) => {
        Object.assign(state, newState)
      })
    }

    function updateState(values: any) {
      Object.assign(state, values)
      update()
    }

    update()

    return { state, update, updateState }
  },
})
</script>
