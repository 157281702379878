<template>
  <template v-for="value in filteredValues" :key="value.id">
    <div v-if="!visible || visible.includes(value.id)" class="group" :class="{ inset, small }">
      <slot :value="value" :parent="isParent(value.id)" />
      <div v-if="$slots.children || !expanded || expanded.includes(value.id)" class="children">
        <nested
          v-if="!expanded || expanded.includes(value.id)"
          :values="values"
          :parent_id="value.id"
          :visible="visible"
          :expanded="expanded"
          :inset="inset"
          :small="small"
        >
          <template #default="props">
            <slot v-if="props?.value" :value="props?.value" :parent="isParent(props?.value?.id)" />
          </template>
        </nested>
        <slot :value="value" name="children" />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Nested',
  props: {
    values: Array as PropType<Array<Nested>>,
    expanded: Array as PropType<Array<number>>,
    visible: Array as PropType<Array<number>>,
    parent_id: Number,
    inset: Boolean,
    small: Boolean,
  },
  setup(props) {
    const filteredValues = computed(() => {
      return (props.values ?? []).filter((v) => {
        if (props.parent_id === null || props.parent_id === undefined) {
          return v.parent_id === null || v.parent_id === undefined
        } else {
          return v.parent_id === props.parent_id
        }
      })
    })

    function isParent(id: number) {
      return (props.values ?? []).filter((v) => v.parent_id === id).length > 0
    }

    return { filteredValues, isParent }
  },
})
</script>

<style lang="scss" scoped>
.group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .children {
    position: relative;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-left: 1rem;

    > .group.inset {
      position: relative;
      &::after {
        pointer-events: none;
        position: absolute;
        content: ' ';
        left: -1rem;
        top: 1.25rem;
        right: -0.5rem;

        border-bottom: 2px solid black;

        height: 0.5rem;
        z-index: -1;
      }
    }
  }

  &.inset {
    .children {
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 0.5rem;
      margin-right: 0.5rem;

      &::after {
        position: absolute;
        content: ' ';
        left: 0;
        right: 0;
        top: -0.25rem;
        bottom: 1.25rem;

        pointer-events: none;
        border-left: 2px solid black;
        border-right: 2px solid black;
      }
    }
  }

  &.small {
    gap: 0.25rem;

    .form-check-input {
      width: 1rem;
      height: 1rem;
    }

    .children {
      gap: 0.25rem;
      padding-left: 0.5rem;
      > .group.inset {
        &::after {
          left: -0.5rem;
          top: 0.625rem;
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }

    &.inset {
      .children {
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0rem;
        margin-right: 0rem;

        &::after {
          left: 0;
          width: 1rem;
          top: -0.25rem;
          bottom: 1rem;
          border-right: none;
        }
      }
    }
  }
}
</style>
