<template>
  <div class="places-map flex-grow-1 position-absolute top-0 start-0 h-100 w-100 d-flex overflow-hidden">
    <map-view
      ref="mapview"
      :data_loaded="Array.isArray(markers)"
      :markers="markers"
      :map_data="map_data"
      @markerClicked="setMarkerActive"
    />

    <div ref="offcanvasRef" class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas">
      <div class="position-absolute top-0 end-0 p-3">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <h5 class="p-3 m-0">Place</h5>

      <div v-if="marker?.place" class="d-flex flex-column p-4">
        <Properties
          class="offcanvas-body"
          :object="{
            Name: marker.place?.title,
            Status: marker.place?.poi_status ?? marker.place.status,
            Source: marker.place?.source,
            Location: [
              {
                lat: marker.lat,
                lng: marker.lng,
              },
            ],
          }"
        />
        <hr />
        <router-link class="btn btn-primary mb-3" :to="{ name: `view-${route}`, params: { id: marker.place.id } }">
          View More
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
import { Offcanvas } from 'bootstrap'

import { MapView } from '@avriopolis/common/components'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'

import project from '@/store/project'
import { fetch } from '@/store/cms'
import Properties from '@/components/misc/Properties.vue'

export default defineComponent({
  name: 'Places_Map',
  emits: ['loading'],
  props: {
    filters: {
      type: Object,
      default: {},
    },
    collection: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
  components: {
    MapView,
    LocalisedValue,
    Properties,
  },
  setup(props, { emit }) {
    const places = ref<any[]>()
    const mapview = ref<typeof MapView>(),
      offcanvasRef = ref(),
      offcanvas = ref(),
      marker = ref<{ lat: number; lng: number; place: MapPlace }>()

    const markers = computed(() => {
      if (!places.value) return undefined
      return places.value
        .map((place) => {
          const place_fields = place.fields || []
          const lat = place_fields.find((f: any) => f.fields_id === 15)?.value
          const lng = place_fields.find((f: any) => f.fields_id === 16)?.value
          return lat && lng ? { place, lat, lng } : undefined
        })
        .filter((m) => !!m)
    })

    const map_data = computed(() => {
      return {
        center: [project?.active?.lng, project?.active?.lat],
      }
    })

    function setMarkerActive(e: any) {
      if (offcanvas.value) {
        marker.value = e
        if (marker.value) {
          offcanvas.value.show()
        }
      }
    }

    async function fetchData() {
      if (mapview.value) {
        mapview.value.mapLoaded = false
      }
      places.value = await fetch(props.collection, {
        limit: -1,
        fields: '*,fields.*',
        filter: JSON.stringify({ ...props.filters, projects_id: project?.active?.id }),
      })
    }

    onMounted(() => {
      offcanvas.value = new Offcanvas(offcanvasRef.value)
    })

    watchEffect(() => {
      if (props.filters) {
        console.log('State Filters changed')
        fetchData()
      }
    })

    return {
      markers,
      map_data,
      setMarkerActive,
      offcanvasRef,
      marker,
      mapview,
    }
  },
})
</script>

<style scoped>
@media screen and (max-height: 600px) {
  .places-map {
    min-height: calc(100vh - 4rem);
  }
}
</style>
