<template>
  <div class="d-flex flex-column flex-grow-1">
    <PageHeader>
      <template #default>Dashboard</template>
      <template #subtitle>{{ project?.name }}</template>
      <template #message>
        Welcome
        <a href="javascript:void(0)" class="font-w600">{{ user?.full_name }}</a>
        <span>, everything looks great.</span>
      </template>
      <template #right>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item">
            <a class="nav-link disabled">Display</a>
          </li>
          <div class="d-flex flex-row">
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { view: 'analytics' } }">
                Analytics
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { view: 'status' } }">
                Status
              </router-link>
            </li>
          </div>
        </ul>
      </template>
    </PageHeader>
    <template v-if="$route.params.view === 'analytics'">
      <div class="content d-flex flex-grow-1">
        <h4 v-if="!project?.temp_dashboard">Coming Soon</h4>
        <iframe
          v-else
          width="100%"
          height="100%"
          :allowtransparency="true"
          style="background: #ffffff"
          :src="project?.temp_dashboard"
        ></iframe>
      </div>
    </template>
    <template v-if="$route.params.view === 'status'">
      <div class="content flex-grow-1 statuses">
        <div
          :data-last-updated="lastUpdated"
          v-for="status in statuses"
          :class="{ danger: moment(status.last_ping).diff(moment()) < -300000 }"
        >
          <ScreenCard
            :name="status?.kiosk_name"
            :id="status?.id"
            :lastPing="status?.last_ping"
            :screenShot="status?.screenshot"
            :restart="status?.restart"
            :playerVersion="status?.player_version"
            :playerUptime="status?.player_uptime"
            :projectVersion="status?.project_version"
            :machineUptime="status?.machine_uptime"
            @updateData="updateData"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

import { PageHeader, Spinner } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import project from '@/store/project'
import axios from '@/api'
import moment from 'moment'

import ScreenCard from '../../components/misc/ScreenCard.vue'

export default defineComponent({
  name: 'Dashboard',
  emits: ['loading'],
  components: {
    PageHeader,
    Spinner,
    ScreenCard,
  },
  beforeRouteUpdate(to, from) {
    console.log(to.params.view, from.params.view)
    if (to.params.view && from.params.view) {
      if (to.params.view === 'status' && from.params.view !== 'status') this.updateData(true)
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.view) {
      return next({ ...to, params: { ...to.params, view: 'analytics' } })
    }
    next()
  },
  setup(props, { emit }) {
    const statuses = ref<Status[]>([])

    const fetchData = async () => {
      const response = await axios.get(
        `/items/status?filter[projects_id]=${project.active?.id}&fields=*,screenshot.id,screenshot.modified_on,screenshot.uploaded_on`,
      )

      return response?.data?.data
    }

    async function updateData(loading: boolean = true) {
      loading && emit('loading', true)
      let status = statuses.value
      try {
        status = await fetchData()
      } catch (e) {}
      loading && emit('loading', false)
      statuses.value = status
    }

    updateData()

    const lastUpdated = ref(new Date().getTime())
    const timestamp = ref(new Date().getTime())
    const interval = ref(),
      timer = ref()

    onMounted(() => {
      interval.value = setInterval(() => {
        lastUpdated.value = new Date().getTime()
        updateData()
      }, 60000)

      timer.value = setInterval(() => {
        timestamp.value = new Date().getTime()
      }, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
    })

    return {
      lastUpdated,
      moment,
      user: Auth.user,
      project: project.active,
      statuses,
      updateData,

      timestamp,
      mediaBase: import.meta.env.VITE_API_URL,
    }
  },
})
</script>

<style lang="scss" scoped>
.statuses {
  display: grid;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 4rem;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    justify-content: start;
  }

  grid-template-columns: repeat(auto-fill, 300px);
}
</style>
