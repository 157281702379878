<template>
  <div class="d-flex flex-column border rounded-3 bg-white flex-grow-1 pb-3">
    <div class="px-2 pt-2">
      <div class="p-3" :class="{ archived: newData.status === 'archived' }">
        <properties
          :object="{
            ID: media.id,
            Title: media.files_id?.title,
            Status: media.status,
            'Date Created': new Date(media.date_created).toLocaleString(),
            'Last Updated': new Date(media.date_updated).toLocaleString(),
          }"
        />
        <hr />
        <div class="d-flex justify-content-between">
          <CSVEditor type="Tag" :values="media.tags ?? []" @input="update({ tags: $event })" />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <CSVEditor type="Type" :values="media.types ?? []" @input="update({ types: $event })" />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <span>Locked</span>
          <div class="form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="media.locked"
              @change="update({ locked: $event.target.checked })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="px-2">
      <div class="px-3">
        <hr />
        <div class="d-flex justify-content-end gap-2">
          <button
            v-if="newData.status === 'archived'"
            class="btn btn-outline-warning"
            @click="restore(['status', 'points_of_interest_id', 'announcements_id', 'components_id'])"
          >
            <span class="material-icons me-2">unarchive</span>
            <span>Unarchive</span>
          </button>
          <button
            v-if="newData.status !== 'archived'"
            class="btn btn-outline-danger"
            @click="
              update({ status: 'archived', points_of_interest_id: null, announcements_id: null, components_id: null })
            "
          >
            <span class="material-icons me-2">archive</span>
            <span>Archive</span>
          </button>
        </div>
      </div>
      <div class="px-3" v-if="hasChanges()">
        <hr />
        <div class="d-flex justify-content-end gap-2">
          <button class="btn btn-outline-primary" @click="processChanges(true)">
            <span class="material-icons me-2">save</span>
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue'

import { pushChange, hasChanges, processChanges } from '@/store/changes'
import { CSVEditor } from '@avriopolis/common/components'
import Properties from '@/components/misc/Properties.vue'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'MediaEditor',
  emits: ['update'],
  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
  },
  components: {
    CSVEditor,
    Properties,
  },
  setup(props) {
    const newData = reactive({
      tags: props.media?.tags ?? [],
      types: props.media?.types ?? [],
      locked: props.media?.locked,
    })

    function restore(keys: string[]) {
      const newObj = keys.reduce((p, c) => ({ ...p, [c]: undefined }), {})
      for (let key of keys) delete newData[key]
      pushChange('Media', props.media.id, newObj)
    }

    function update(data: any) {
      let allEqual = true
      for (let key of Object.keys(data)) {
        let prop = (props.media as any)[key]
        if (Array.isArray(data[key])) {
          prop = prop ?? []
        }
        if (!isEqual(prop, data[key])) {
          allEqual = false
        }
      }

      if (!allEqual) {
        pushChange('Media', props.media.id, data)
      } else {
        pushChange(
          'Media',
          props.media.id,
          Object.keys(data).reduce((p, c) => ({ [c]: undefined }), {}),
        )
      }

      Object.assign(newData, data)
    }

    return { update, pushChange, hasChanges, newData, restore, processChanges }
  },
})
</script>

<style lang="scss" scoped>
.archived {
  filter: grayscale(1);
  opacity: 0.5;

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>
