import { reactive } from 'vue'
import axios from '@/api'

type Project = {
  id: number
  name: string
  temp_dashboard: string
  lat: number
  lng: number
  languages: string[]
}

type ProjectState = {
  active?: Project
  projects: Project[]
}

export const state = reactive<ProjectState>({
  active: undefined,
  projects: [],
})

export const isProjectSelected = () => {
  return !!(state.active ?? false)
}

export const getProjectId = () => {
  return isProjectSelected() ? state.active?.id || -1 : -1
}

export const setProjectId = (projectId: number) => {
  sessionStorage.setItem('projectId', `${projectId}`)
  state.active = state.projects.find((project) => project.id === projectId)
}

export const fetchProjects = async () => {
  const response = await axios.get('/items/projects')
  if (response?.data?.data) {
    state.projects = response?.data?.data
    setProjectId(parseInt(sessionStorage.getItem('projectId') ?? '-1') || -1)
  }
}

export default state
