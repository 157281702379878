<template>
  <div class="cms-table position-relative card card-rounded mb-3">
    <paginated-table
      :title="title"
      ref="table"
      :headers="headers"
      :rows="rows"
      :view="view"
      :edit="edit"
      :archive="archive"
      @update="update"
      @view="handle('view', $event)"
      @edit="handle('edit', $event)"
      @archive="handle('archive', $event)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { fetch } from '@/store/cms'

import project from '@/store/project'

import PaginatedTable from '@/components/pages/PaginatedTable.vue'

import { useRouter } from 'vue-router'
import * as Headers from '@/utils/headers'

export default defineComponent({
  name: 'CMSTable',
  components: {
    PaginatedTable,
  },
  props: {
    name: String,
    title: String,
    view: Boolean,
    edit: Boolean,
    archive: Boolean,
    filters: Object,
  },
  emits: ['archive'],
  setup(props, { emit }) {
    const router = useRouter()

    const table = ref()
    const rows = ref<any[]>()

    const headers = computed(() => {
      switch (props.name) {
        case 'points_of_interest':
          return Headers.POINTS_OF_INTEREST
        case 'announcements':
          return Headers.ANNOUNCEMENTS
        case 'components':
          return Headers.COMPONENTS
        default:
          return {}
      }
    })

    function handleUpdate(response: any, updatePagination: (state: any) => {}) {
      rows.value = response.data
      if (updatePagination) {
        updatePagination({
          items: response.meta.filter_count,
          loading: false,
        })
      }
    }

    async function update(state: any, updatePagination: (state: any) => {}) {
      const filter = {
        page: state.page,
        sort: state.sort,
        limit: 10,
        meta: '*',
        fields: '*,template_id.*',
        filter: JSON.stringify({ ...(props.filters ?? {}), projects_id: project?.active?.id }),
      }

      let response = undefined
      if (props.name === 'points_of_interest') {
        response = await fetch('points_of_interest', filter as any)
      } else if (props.name === 'components') {
        response = await fetch('components', filter as any)
      } else if (props.name === 'announcements') {
        response = await fetch('announcements', filter as any)
      }
      if (response) {
        handleUpdate(response, updatePagination)
      }
    }

    function handle(event: string, id: number) {
      switch (event) {
        case 'edit':
        case 'view':
          router.push({ name: `${event}-${props.name}`, params: { id } })
          break

        case 'archive':
          emit('archive', { name: props.name, id })
          break
      }
    }

    watchEffect(() => {
      if (table.value) {
        table?.value?.update()
      }
    })

    return { rows, update, table, handle, headers }
  },
})
</script>
