<template>
  <div v-if="templates" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>Pages</template>
      <template #right>
        <button
          class="btn btn-primary d-flex btn-rounded text-nowrap"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <span class="material-icons me-2">add</span>
          Create Page
        </button>
      </template>
    </PageHeader>
    <transition appear name="fade">
      <div class="content d-flex flex-column flex-grow-1 position-relative">
        <CMSTable ref="table" title="Components" name="components" view edit />
      </div>
    </transition>

    <teleport to="#modals">
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog create-announcement">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Select Template</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex gap-2">
              <div
                class="template border d-flex flex-column gap-2 flex-grow-1 p-2"
                :class="{ active: activeTemplate === template.id }"
                @click="activeTemplate = template.id"
                v-for="template in templates"
                :key="template.id"
              >
                <h5 class="m-0 p-0">{{ template.key }}</h5>
                <div class="img-wrapper">
                  <img :src="`${mediaBase}/assets/${template.preview}`" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                :disabled="activeTemplate === -1"
                @click="createPage"
              >
                Create Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Swal from 'sweetalert2'

import axios from '@/api'

import { PageHeader } from '@avriopolis/common/components'
import CMSTable from '@/components/misc/CMSTable.vue'
import project from '@/store/project'
import { slugify } from '@/utils/misc'

export default defineComponent({
  name: 'Points_of_Interest',
  emits: ['loading'],
  components: {
    PageHeader,
    CMSTable,
  },
  setup(props, { emit }) {
    const templates = ref<Template[]>()
    const activeTemplate = ref<number>(-1)
    const table = ref()

    const fetchData = async () => {
      const response = await axios.get(`/items/component_templates`)
      templates.value = response?.data?.data?.filter(
        (template: any) => !template?.projects_id || template.projects_id === project.active?.id,
      )
    }

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    async function createPage() {
      const result = await Swal.fire({
        input: 'text',
        inputLabel: 'Component Title',
        inputPlaceholder: 'Title',
        showCancelButton: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0d6efd',
      })

      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Invalid', 'A title must be entered', 'error')
        } else {
          await axios.post('items/components', {
            key: slugify(result.value),
            source: 'manual',
            projects_id: project.active?.id,
            template_id: activeTemplate.value,
            metadata: JSON.stringify({ title: [{ lang: 'en-US', value: result.value }] }),
          })
          table?.value?.table?.update()
        }
      }
    }

    updateData()

    return { templates, activeTemplate, createPage, table, mediaBase: import.meta.env.VITE_API_URL }
  },
})
</script>

<style lang="scss" scoped>
.template {
  .img-wrapper {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.active {
    background: #efefef;
  }
}

@media screen and (min-width: 576px) {
  .create-announcement {
    max-width: 80%;
  }
}
@media screen and (min-width: 750px) {
  .create-announcement {
    max-width: 700px;
  }
}
</style>
