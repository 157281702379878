import { createRouter } from '@avriopolis/common'

import { isProjectSelected } from '@/store/project'
import { routeGuard as changesGuard } from '@/store/changes'
import auth from '@/store/auth'

import { routeGuard as activityGuard } from '@/store/activity'

import Index from '@/views/layout/Index.vue'
import Dashboard from '@/views/pages/Dashboard.vue'
import SelectProject from '@/views/pages/SelectProject.vue'

import PointsOfInterest from '@/views/pages/points_of_interest/Index.vue'
import ViewPointsOfInterest from '@/views/pages/points_of_interest/View.vue'
import EditPointsOfInterest from '@/views/pages/points_of_interest/Edit.vue'

import Components from '@/views/pages/components/Index.vue'
import ViewComponent from '@/views/pages/components/View.vue'
import EditComponent from '@/views/pages/components/Edit.vue'

import Announcements from '@/views/pages/announcements/Index.vue'
import ViewAnnouncement from '@/views/pages/announcements/View.vue'
import EditAnnouncement from '@/views/pages/announcements/Edit.vue'

import Gallery from '@/views/pages/Gallery.vue'
import Categories from '@/views/pages/Categories.vue'
import Login from '@/views/pages/Login.vue'
import LoginCallback from '@/views/pages/LoginCallback.vue'
import SearchResults from '@/views/pages/search/SearchResults.vue'
import Navigation from '@/views/pages/menu/Navigation.vue'

const router = createRouter([
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: { guestOnly: true, requiresAuth: false, projectRequired: false },
  },
  {
    name: 'validate-auth',
    path: '/validate-auth',
    component: LoginCallback,
    meta: { guestOnly: true, requiresAuth: false, projectRequired: false },
  },
  {
    name: 'select-project',
    path: '/project',
    component: SelectProject,
    meta: { projectRequired: false },
  },
  {
    name: 'root',
    path: '/',
    component: Index,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard/:view?',
        component: Dashboard,
      },
      {
        name: 'points_of_interest',
        path: 'points_of_interest/:display_as?',
        component: PointsOfInterest,
      },

      {
        name: 'edit-points_of_interest',
        path: 'points_of_interest/:id/edit',
        component: EditPointsOfInterest,
      },
      {
        name: 'view-points_of_interest',
        path: 'points_of_interest/:id/:tab?',
        component: ViewPointsOfInterest,
      },
      {
        name: 'announcements',
        path: 'announcements/:display_as?',
        component: Announcements,
      },

      {
        name: 'edit-announcements',
        path: 'announcement/:id/edit',
        component: EditAnnouncement,
      },
      {
        name: 'view-announcements',
        path: 'announcement/:id/:tab?',
        component: ViewAnnouncement,
      },
      {
        name: 'components',
        path: 'components',
        component: Components,
      },
      {
        name: 'view-components',
        path: 'components/:id/:tab?',
        component: ViewComponent,
      },
      {
        name: 'edit-components',
        path: 'components/:id/edit',
        component: EditComponent,
      },
      {
        name: 'navigation',
        path: 'navigation/:id?',
        component: Navigation,
      },
      {
        name: 'media',
        path: 'media',
        component: Gallery,
      },
      {
        name: 'categories',
        path: 'categories',
        component: Categories,
      },
      {
        name: 'search',
        path: 'search/:query',
        component: SearchResults,
      },
      {
        path: ':catchAll(.*)',
        redirect: { name: 'dashboard', params: { view: 'analytics' } },
      },
      {
        path: '',
        redirect: { name: 'dashboard', params: { view: 'analytics' } },
      },
    ],
  },
])

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth || to.meta.requiresAuth == undefined) {
    if (auth.user.value) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    if (to.meta.guestOnly && auth.user.value) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  }
})

router.beforeEach(async (to, from, next) => {
  if (auth.user.value) {
    const projectRequired = to.matched.some(
      (route) => route.meta?.projectRequired || route.meta?.projectRequired === undefined,
    )
    if (projectRequired) {
      if (isProjectSelected() == true) {
        next()
      } else {
        next({ name: 'select-project' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach(changesGuard)
router.beforeEach(activityGuard)

export default router
