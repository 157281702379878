<template>
  <slot :value="localisedValue">{{ localisedValue }}</slot>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import i18n from '@avriopolis/common/i18n'

export default defineComponent({
  name: 'LocalisedValue',
  props: {
    value: {
      required: true,
    },
  },
  setup(props) {
    const localisedValue = computed(() => {
      if (Array.isArray(props.value) && props.value.length > 0 && props.value[0]?.lang) {
        const entry = props.value.find((e: any) => e.lang === i18n.global.locale)
        return entry?.value ?? 'N/A'
      }
      return props.value
    })
    return { localisedValue }
  },
})
</script>
