import axios from '@/api'
import project from '@/store/project'

export const fetchCategories = async (projects_id?: number | null) => {
  if (projects_id === undefined) projects_id = project?.active?.id
  const filter = projects_id ? { 'filter[projects_id][_eq]': projects_id } : { 'filter[projects_id][_null]': true }
  return await fetch('categories', {
    limit: -1,
    ...filter,
  })
}

export const fetch = async (collection: string, params?: any) => {
  const query = new URLSearchParams({ ...params }).toString()
  const response = await axios.get(`/items/${collection}?${query}`)

  if (params && params.hasOwnProperty('meta')) {
    if (response?.data?.data) {
      return response?.data
    } else {
      return {
        data: [],
        meta: {},
      }
    }
  }

  if (response?.data?.data) {
    return response?.data?.data
  } else {
    return []
  }
}

export const search = async (query: string): Promise<SearchResults> => {
  //{ 'filter[projects_id][_eq]': projects_id }
  const points_of_interest = await axios.get(
    `/items/points_of_interest?search=${query}&fields=*.*&filter[projects_id]=${project?.active?.id}`,
  )
  const components = await axios.get(
    `/items/components?search=${query}&fields=*.*&filter[projects_id]=${project?.active?.id}`,
  )
  const announcements = await axios.get(
    `/items/announcements?search=${query}&fields=*.*&filter[projects_id]=${project?.active?.id}`,
  )
  const media = await axios.get(`/items/media?search=${query}&fields=*.*&filter[projects_id]=${project?.active?.id}`)

  return {
    points_of_interest: points_of_interest?.data?.data,
    components: components?.data?.data,
    announcements: announcements?.data?.data,
    media: media?.data?.data,
  }
}
