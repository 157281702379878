<template>
  <div>
    <div class="d-flex justify-content-between bg-white p-2">
      <h6 class="m-0 p-2">
        {{ title }}
      </h6>
      <div class="btn-group">
        <button class="btn d-inline-flex align-items-center p-1 me-2" type="button" @click="$emit('update')">
          <i class="material-icons md-18">refresh</i>
        </button>
        <button
          class="btn d-inline-flex align-items-center p-1 me-2"
          type="button"
          id="dropdownMenuClickableInside"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <i class="material-icons md-18">tune</i>
        </button>
        <ul class="dropdown-menu px-2" aria-labelledby="dropdownMenuClickableInside">
          <li>
            <div class="form-check form-switch" v-for="(header, i) in headers" :key="i">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`toggle-${header.key}`"
                :checked="visibleHeaders.includes(header.key)"
                @input="toggleHeader(header.key)"
              />
              <label class="form-check-label" :for="`toggle-${header.key}`">{{ header.key }}</label>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table table-hover mb-0">
        <thead>
          <tr>
            <th scope="col" v-for="(header, i) in filteredHeaders" :key="i" :class="{ sortable: header.sortable }">
              <template v-if="header.sortable">
                <div class="d-flex justify-content-between" @click="sortBy(header)">
                  {{ header.text ?? header.key }}
                  <template v-if="sort.replace(/^\-/, '') === header.key">
                    <span v-if="sort.indexOf('-')" class="material-icons md-18">south</span>
                    <span v-else class="material-icons md-18">north</span>
                  </template>
                  <span v-else class="material-icons">swap_vert</span>
                </div>
              </template>
              <template v-else>
                {{ header.text ?? header.key }}
              </template>
            </th>
            <th v-if="view || edit || archive"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in rows" :key="i">
            <template v-for="(header, i) in filteredHeaders" :key="i">
              <td v-if="row[header.key] || header.type === 'func'">
                <template v-if="header.type === 'func'">
                  {{ header['_get'](row) ?? 'N/A' }}
                </template>
                <template v-if="header.type === 'basic'">
                  {{ row[header.key] }}
                </template>
                <template v-if="header.type === 'array'">
                  <span class="badge bg-primary" v-for="(item, i) in row[header.key]" :key="i">
                    {{ item }}
                  </span>
                </template>
                <template v-if="header.type === 'localised-value'">
                  <localised-value :value="row[header.key]"></localised-value>
                </template>
                <template v-if="header.type === 'date'">
                  {{ new Date(row[header.key]).toLocaleString() }}
                </template>
                <template v-if="header.type === 'user'">
                  {{ row[header.key] }}
                </template>
              </td>
              <td v-else />
            </template>
            <td v-if="view || edit || archive" class="text-end" style="white-space: nowrap">
              <button
                v-if="view"
                class="d-inline-flex btn btn-primary me-2 align-items-center p-1"
                @click="$emit('view', row.id)"
              >
                <i class="material-icons md-18">link</i>
              </button>
              <button
                v-if="edit"
                class="d-inline-flex btn btn-outline-secondary me-2 align-items-center p-1"
                @click="$emit('edit', row.id)"
              >
                <i class="material-icons md-18">edit</i>
              </button>
              <button
                v-if="archive"
                class="d-inline-flex btn btn-outline-danger me-2 align-items-center p-1"
                @click="$emit('archive', row.id)"
              >
                <i class="material-icons md-18">archive</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <spinner v-if="loading" fill_parent />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import { Spinner } from '@avriopolis/common/components'

export default defineComponent({
  name: 'Table',
  emits: ['sort', 'view', 'edit', 'archive', 'update'],
  props: {
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    rows: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    sortable: {
      type: Boolean,
      required: false,
    },
    sort: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
    },
    view: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
    },
    archive: {
      type: Boolean,
    },
  },
  components: { LocalisedValue, Spinner },
  setup(props, { emit }) {
    function sortBy(header: any) {
      const active = (props.sort ?? '').replace(/^\-/, '') === header.key
      if (!active) {
        emit('sort', header.key)
      } else {
        if ((props.sort ?? '').indexOf('-') === 0) {
          emit('sort', '')
        } else {
          emit('sort', `-${header.key}`)
        }
      }
    }

    const visibleHeaders = ref<String[]>([])

    function resetVisibleHeaders() {
      visibleHeaders.value.length = 0
      for (let header of props.headers) {
        if (header.visible ?? true) {
          visibleHeaders.value.push(header.key)
        }
      }
    }

    function updateHeaders() {
      try {
        if (props.title) {
          const visibleHeaderCacheJSON = localStorage.getItem(`table-headers-${props.title}`)
          if (visibleHeaderCacheJSON) {
            const visibleHeadersCache = JSON.parse(visibleHeaderCacheJSON)
            visibleHeaders.value = visibleHeadersCache
            return
          }
        }
      } catch (e) {}
      resetVisibleHeaders()
    }

    function toggleHeader(header: string) {
      if (visibleHeaders.value.includes(header)) {
        visibleHeaders.value.splice(visibleHeaders.value.indexOf(header), 1)
      } else {
        visibleHeaders.value.push(header)
      }

      if (props.title) {
        localStorage.setItem(`table-headers-${props.title}`, JSON.stringify(visibleHeaders.value))
      }
    }

    updateHeaders()

    const filteredHeaders = computed(() =>
      props.headers.filter((h: any) => {
        return visibleHeaders.value.includes(h.key)
      }),
    )

    return { sortBy, visibleHeaders, filteredHeaders, toggleHeader }
  },
})
</script>

<style lang="scss" scoped>
.table-wrapper {
  position: relative;
  overflow-x: auto;
  min-height: 15rem;

  .table {
    th {
      user-select: none;
      text-transform: capitalize;

      background: #fafafa;
      border-bottom: 0;
      color: #999;
      font-size: 0.9em;
      white-space: nowrap;

      &.sortable {
        cursor: pointer;
      }
    }
    td {
      white-space: nowrap;
    }
  }
}
</style>
