import { reactive } from 'vue'
import { NavigationGuard } from 'vue-router'
import { isEmpty, pickBy } from 'lodash'
import Swal from 'sweetalert2'
import { omit } from 'lodash'
import axios from '@/api'

type ChangesState = {
  [key: string]: {
    [key: string]: { [key: string]: any }
  }
}

export const state = reactive<ChangesState>({})

export function pushChange(type: string, id: any, value: { [key: string]: any } | []) {
  if (!state[type]) {
    state[type] = {}
  }

  const newValue = Array.isArray(value)
    ? value.filter((c) => c !== undefined)
    : pickBy({ ...state[type][id], ...value }, (v) => v !== undefined)
  if (isEmpty(newValue)) {
    if (state[type][id] !== undefined) {
      delete state[type][id]
    }
  } else {
    state[type][id] = newValue
  }

  console.log(JSON.stringify(state, null, 4))
}

async function clearChanges() {
  for (let key of Object.keys(state)) {
    delete state[key]
  }
}

async function handleSave(key: string, id: string, params: any): Promise<boolean> {
  try {
    switch (key) {
      case 'Points_of_Interest':
        await axios.patch(`/items/points_of_interest/${id}`, params)
        break
      case 'Points_of_Interest_Field':
        await axios.patch(`/items/point_of_interest_fields/${id}`, params)
        break
      case 'Points_of_Interest_Categories':

        let { existing, categories } = params as { existing: { id: number, category: number, locked: boolean }[], categories: number[] };


        const adding = categories.filter(c => !existing.find(e => e.category === c));
        const removing = existing.filter(e => !categories.includes(e.category));

        for(const categories_id of adding) {
          await axios.post(`/items/points_of_interest_categories`, { categories_id, points_of_interest_id: id })
        }

        for (const remove of removing) {
          await axios.delete(`/items/points_of_interest_categories/${remove.id}`)
        }
        break
      case 'Points_of_Interest_Categories_Locked':
        await axios.patch(`/items/points_of_interest_categories/${id}`, params)
        break
      case 'Announcements':
        await axios.patch(`/items/announcements/${id}`, params)
        break
      case 'Announcements_Field':
        await axios.patch(`/items/announcement_fields/${id}`, params)
        break
      case 'Category':
        await axios.patch(`/items/categories/${id}`, params)
        break
      case 'Component':
        await axios.patch(`/items/components/${id}`, params)
        break
      case 'ComponentMeta':
        await axios.patch(`/items/components/${id}`, { metadata: params })
        break
      case 'Media':
        await axios.patch(`/items/media/${id}`, params)
        break
      case 'Menu':
        await axios.patch(`/items/menus/${id}`, params)
        break
      case 'ComponentMedia':
        switch (parseInt(id)) {
          case 0:
            await axios.post(`/items/media`, params)
            break
          default:
            await axios.patch(`/items/media/${id}`, params)
            break
        }
        break
      case 'ComponentLocalisedMedia':
        console.log('Save Localised:', id, params)
        await axios.post(`/items/media`, params)
        break
      case 'Exceptions':
        switch (parseInt(id)) {
          case -1:
            await axios.delete(`/items/exceptions`, params)
            break
          case 0:
            await axios.post(`/items/exceptions`, params)
            break
          default:
            await axios.patch(`/items/exceptions/${id}`, params)
            break
        }
        break
      default:
        console.log('Unhandled Case', key, id, JSON.stringify(params, null, 4))
    }
    delete state[key][id]
  } catch (e) {
    console.log(e)
  }
  return true
}

async function saveChanges(): Promise<boolean> {
  Swal.fire({
    title: `Saving your changes!`,
    icon: 'info',
    confirmButtonColor: '#0d6efd',
    allowOutsideClick: false,
    didOpen: () => Swal.showLoading(),
  })

  for (let key of Object.keys(state)) {
    for (let id of Object.keys(state[key])) {
      if (!(await handleSave(key, id, state[key][id]))) {
        return false
      }
    }
  }

  Swal.close()
  return true
}

export async function promptForAction() {
  const result = await Swal.fire({
    title: 'Unsaved changes',
    text: 'You have unsaved changes, would you like to save or discard?',
    icon: 'warning',
    confirmButtonText: 'Save',
    showConfirmButton: true,
    denyButtonText: 'Discard',
    showDenyButton: true,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#0d6efd',
  })

  if (result.isConfirmed) {
    if (await saveChanges()) return true
    return false
  } else if (result.isDenied) {
    await clearChanges()
    return true
  } else {
    return false
  }
}

export function hasChanges() {
  for (let key of Object.keys(state)) {
    if (Object.keys(state[key]).length > 0) {
      return true
    }
  }
  return false
}

export async function processChanges(autoSave: boolean) {
  if (hasChanges()) {
    if (autoSave) {
      if (await saveChanges()) return true
      return false
    }
    return await promptForAction()
  }
  return true
}

export const routeGuard: NavigationGuard = async (to, from, next) => {
  if (hasChanges()) {
    console.log('Save Changes')
    if (await processChanges(!!to.params.save)) {
      next({ ...to, params: omit(to.params, 'save') })
    } else {
      next(false)
    }
  } else {
    console.log('Go Next')
    next()
  }
}
