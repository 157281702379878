<template>
  <div v-if="state.component" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>{{ state?.component?.key }}</template>
      <template #message>{{ state?.component?.template_id.key }}</template>
      <template #right>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item">
            <a class="nav-link disabled">Display</a>
          </li>
          <div class="d-flex flex-row">
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'info' } }">
                Info
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'media' } }">
                Media
              </router-link>
            </li>
          </div>
        </ul>
      </template>
    </PageHeader>
    <transition appear name="fade" mode="out-in">
      <div
        v-if="$route.params.tab === 'info'"
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <properties
              v-if="state.component"
              :object="{
                Template: state.component?.template_id.key,
                Status: state.component?.status,
                'Date Created': new Date(state.component?.date_created).toLocaleString(),
                'Last Updated': new Date(state.component?.date_updated).toLocaleString(),
              }"
            />
          </div>
          <div class="d-flex justify-content-end gap-2">
            <router-link class="btn btn-secondary btn-sm d-flex" :to="{ name: 'edit-components' }">
              <span class="material-icons me-2">edit</span>
              <span class="fs-6">Edit</span>
            </router-link>
            <router-link class="btn btn-danger btn-sm d-flex" :to="{ name: 'edit-components' }">
              <span class="material-icons me-2">archive</span>
              <span class="fs-6">Archive</span>
            </router-link>
          </div>
        </div>

        <metadata-view :template="state.component?.template_id.metadata" :values="state.component.metadata" />
      </div>
      <div class="content" v-else>
        <media-viewer
          :canUpload="false"
          :query="{ filter: { components_id: state.component?.id } }"
          @loading="$emit('loading', $event)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/api'

import { PageHeader } from '@avriopolis/common/components'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import MetadataView from '@/components/metadata/MetadataView.vue'
import Properties from '@/components/misc/Properties.vue'
import MediaViewer from '@/components/media/MediaViewer.vue'

export default defineComponent({
  name: 'View_Component',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    MetadataView,
    Properties,
    MediaViewer,
  },
  setup(props, { emit }) {
    const route = useRoute()
    const state = reactive<{ component?: any }>({
      component: undefined,
    })

    const fetchData = async () => {
      const response = await axios.get(`/items/components/${route.params.id}?fields=*.*`)
      state.component = response?.data?.data
    }

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    updateData()

    return { state }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
