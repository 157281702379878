import axios from 'axios'
import Auth from './store/auth'

const apiAxios = axios.create({
  baseURL: (import.meta.env.VITE_API_URL as string) ?? 'https://api.avriopolis.gloohtest.co.uk',
  headers: {},
  withCredentials: true,
})

let refreshing: Promise<any> | undefined = undefined

apiAxios.interceptors.request.use(async (config) => {
  if (typeof config?.url === 'string' && config.url.includes('/auth')) return config
  if (config.headers && config.withCredentials) {
    config.headers.Authorization = 'Bearer ' + Auth.token.value
  }
  return config
})

apiAxios.interceptors.response.use(
  (r) => r,
  async (error) => {
    console.log('Error with directus :D?', error?.response?.config?.url)

    if (error?.response?.config?.url === '/auth/logout' || error?.response?.config?.url === '/auth/login') {
      return Promise.reject(error)
    }

    if (error?.response?.config?.url === '/auth/refresh') {
      await Auth.logout()
      return Promise.reject(error)
    }

    const errorCode = (error?.response?.data?.errors ?? [])[0]?.extensions?.code
    console.log(error?.response?.status, errorCode)

    if (error?.response?.status === 401 || errorCode === 'INVALID_TOKEN') {
      if (!refreshing) {
        refreshing = Auth.refresh().finally(() => (refreshing = undefined))
      }
      if (refreshing) await refreshing
      if (error?.response?.config) return await apiAxios.get(error?.response?.config)
    }
    return Promise.reject(error)
  },
)

export default apiAxios
