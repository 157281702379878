<template>
  <div class="media-selector overflow-hidden">
    <button class="btn btn-outline-secondary py-1 px-2" @click="openModal">
      <span class="material-icons me-3">collections</span>
      Select Image{{ single ? '' : 's' }}
    </button>
    <button v-if="single && media[0]?.files_id" class="btn btn-primary ms-3 p-1" @click="showPreview">
      <span class="material-icons">visibility</span>
    </button>
    <teleport to="#modals">
      <div
        class="modal fade"
        :id="`selectImage-${id}`"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog select-media center">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Select Image{{ single ? '' : 's' }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex flex-column gap-2 border m-2">
              <media-viewer
                v-if="modalOpen"
                ref="viewer"
                class="h-100 overflow-hidden"
                :single="single"
                horizontal
                :active="(media ?? []).map((c) => c.files_id)"
                :canEdit="false"
                @selected="selectedMedia = $event"
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="$emit('selected', selectedMedia)"
              >
                Submit Image{{ single ? '' : 's' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import Swal from 'sweetalert2'

import MediaViewer from '@/components/media/MediaViewer.vue'
import { Modal } from 'bootstrap'

export default defineComponent({
  name: 'MediaSelector',
  emits: ['selected'],
  props: {
    id: {
      type: String,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Array as PropType<MediaRaw[]>,
      default: [],
    },
  },
  components: {
    MediaViewer,
  },
  setup(props, { emit }) {
    const selectedMedia = ref<DirectusFile[]>()
    const modalOpen = ref(false)

    function showPreview() {
      Swal.fire({
        imageUrl: `${import.meta.env.VITE_API_URL}/assets/${props.media[0].files_id}`,
        imageHeight: 400,
        showConfirmButton: false,
        allowOutsideClick: true,
      })
    }

    function openModal() {
      if (selectedMedia.value) {
        selectedMedia.value.length = 0
      }
      modalOpen.value = true

      const myModalEl = document.getElementById(`selectImage-${props.id}`)
      myModalEl.addEventListener('hidden.bs.modal', () => {
        modalOpen.value = false
      })
      new Modal(myModalEl).show()
      //data-bs-toggle="modal"
      //:data-bs-target="`#selectImage-${id}`"
    }

    return { showPreview, openModal, selectedMedia, modalOpen }
  },
})
</script>

<style lang="scss" scoped>
@media screen and (min-width: 576px) {
  .select-media {
    max-width: 80%;
  }
}
@media screen and (min-width: 1340px) {
  .select-media {
    max-width: 1300px;
  }
}
</style>
