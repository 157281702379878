import axios from '@/api'
import { ref, watch } from 'vue'
import router from '../router'
import i18n from '@/i18n'

type User = {
  id: string
  email: string
  first_name: string
  last_name: string
  full_name: string
  avatar: string
  password: string
  language: string
  location: string
  activity?: string
  socialPage?: string
  createVisual?: string
  role?: {
    id: string
    name: string
  }
  phone: string
  address: string
  city: string
  postal_code: string
  vat: string
  siret: string
}

export type ResponseError = {
  message: string
  extensions: {
    code?: string
    collection?: string
    field?: string
    invalid?: string
  }
}

const token = ref<string | null>(null)
const user = ref<User | null>(null)

addEventListener('storage', (event) => {
  if (event.key === null) {
    if (event.newValue === null) {
      user.value = null
      token.value = null
    }
  } else {
    if (event.key === 'token') {
      token.value = event.newValue
    }
  }
})

try {
  const localToken = localStorage.getItem('token')
  token.value = localToken

  const localUser = localStorage.getItem('user')
  user.value = localUser ? JSON.parse(localUser) : null
} catch (e) {}

watch(token, (token) => {
  if (token) {
    localStorage.setItem('token', token)
  } else {
    localStorage.removeItem('token')
  }
})

watch(user, (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user))
  } else {
    localStorage.removeItem('user')
  }
})

async function refresh() {
  try {
    const { data: refresh, status } = await axios.post('/auth/refresh', { mode: 'cookie' })
    if (status === 200) {
      token.value = refresh.data.access_token

      const response = await axios.get('/users/me?fields=*,role.*')
      const userData = response.data.data
      const hasName = userData.first_name && userData.last_name

      if (i18n.global.availableLocales.includes(userData.language)) {
        i18n.global.locale = userData.language
      }

      user.value = {
        ...userData,
        full_name: hasName ? `${userData.first_name} ${userData.last_name}` : userData.email,
      }
    }
  } catch (e) {}
}

function username() {
  return user.value?.first_name ?? user.value?.email?.split('@')[0] ?? null
}

async function logout() {
  try {
    try {
      axios.post('/auth/logout')
    } catch {}
    token.value = null
    user.value = null
    router.push({ name: 'login' })
  } catch (e) {}
}

async function updateUser(id: string, userData: Partial<User>) {
  try {
    await axios.patch(`/users/${id}`, {
      ...userData,
    })

    user.value = { ...user.value, ...userData } as User

    return true
  } catch (error: any) {
    return (error?.response?.data?.errors as ResponseError[]) ?? false
  }
}

async function resetPassword(email: string) {
  try {
    axios.post('/auth/password/request', {
      email,
    })

    return true
  } catch (error: any) {
    return (error?.response?.data?.errors as ResponseError[]) ?? false
  }
}

export default {
  token,
  user,
  username,
  refresh,
  logout,
  updateUser,
  resetPassword,
}
