<template>
  <template v-if="loading">
    <transition appear name="fade" mode="out-in">
      <Spinner fill_parent />
    </transition>
  </template>
  <template v-else-if="menu">
    <h5 class="p-3 m-0 border-bottom">Editing Menu Item (ID:{{ menu.id }})</h5>
    <transition appear name="fade" mode="out-in">
      <div class="menu d-flex flex-column flex-grow-1 px-4 py-1 pb-4 overflow-auto pt-4" :key="id">
        <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
          <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Key</span>
          <text-input class="border" :value="newData.key" @change="update({ key: $event })" />
        </div>

        <hr />

        <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
          <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Title</span>
          <text-input class="border" :value="newData.title" @change="update({ title: $event })" />
        </div>

        <hr />

        <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
          <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Status</span>
          <select class="form-select" :value="newData.status" @input="update({ status: $event.target?.value })">
            <option v-for="{ text, value } in statuses" :value="value">{{ text }}</option>
          </select>
        </div>

        <hr />

        <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
          <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Menu Display Size</span>
          <select
            class="form-select"
            :value="newData.display_size"
            @input="update({ display_size: parseInt($event.target?.value) })"
          >
            <option v-for="n in 12" :value="n">{{ n }}</option>
          </select>
        </div>

        <hr />
        <div style="height: 1rem"></div>
        <h4 class="mb-0">Targeting</h4>
        <hr />
        <div :class="{ hasChildren }">
          <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
            <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Target Type</span>
            <select
              :disabled="hasChildren"
              class="form-select"
              :value="newData.target_type"
              @input="update({ target_type: $event.target?.value })"
            >
              <option v-for="{ text, value } in types" :value="value">{{ text }}</option>
            </select>
          </div>

          <hr />

          <template v-if="newData.target_type !== 'filter'">
            <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
              <span class="fs-7 fw-bolder text-muted text-nowrap" data-v-19a25a2a="">Target Mode</span>
              <select
                :disabled="hasChildren"
                class="form-select"
                :value="newData.target_mode"
                @input="update({ target_mode: $event.target?.value })"
              >
                <option v-for="{ text, value } in modes" :value="value">{{ text }}</option>
              </select>
            </div>

            <hr />

            <div class="d-flex justify-content-between align-items-center gap-5">
              <span class="fs-7 fw-bolder text-muted text-nowrap">Target ID</span>
              <template v-if="newData.target_type === 'place' || newData.target_type === 'route'">
                <text-input
                  :disabled="hasChildren"
                  class="border"
                  :value="newData.target_id"
                  @change="update({ target_id: $event })"
                />
              </template>
              <template v-if="newData.target_type === 'service'">
                <p>Coming Soon</p>
              </template>
              <template v-else-if="newData.target_type && ['poi', 'event', 'page'].includes(newData.target_type)">
                <custom-select
                  :disabled="hasChildren"
                  :placeholder="`Select ${newData.target_type?.toUpperCase()}`"
                  :options="options"
                  :selected="[parseInt(newData.target_id)]"
                  @selected="update({ target_id: `${$event.id}` })"
                >
                  <template #default="{ option }">
                    <localised-value :value="option?.title ?? option?.key" />
                  </template>
                </custom-select>
              </template>
            </div>
          </template>
          <template v-else>
            <div style="height: 1rem"></div>
            <h4 class="mb-0">Filter By</h4>
            <hr />
            <div class="d-flex flex-column justify-content-center align-items-start gap-2 pb-2">
              <span class="fs-7 fw-bolder text-muted text-nowrap">Categories</span>
              <custom-select
                :placeholder="`Select Categories`"
                :options="categories"
                :selected="newData.event_payload.categories"
                @selected="toggleFilterCategory($event.id)"
              >
                <template #default="{ option }">
                  <localised-value :value="option?.name" />
                </template>
              </custom-select>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start gap-0 pb-2">
              <span class="fs-7 fw-bolder text-muted text-nowrap">Tags</span>
              <CSVEditor class="w-100" type="Tag" :values="newData.event_payload?.tags ?? []" @input="update({ event_payload: { tags: $event, categories: newData.event_payload.categories } })" />
            </div>
          </template>

          
        </div>

        <hr />

        <div style="height: 1rem"></div>
        <h4 class="mb-0">Icon</h4>
        <hr />

        <div class="img-container d-flex flex-column">
          <div v-if="newData.icon" class="img-wrapper d-flex align-items-center mb-4 justify-content-center">
            <img id="image" :src="`${mediaBase}/assets/${newData.icon}`" ref="imageToCrop" />
          </div>
          <media-uploader :createMedia="false" single @uploaded="uploaded" :options="{}" :modal="false" />
        </div>
        
        <template v-if="hasChanges()">
          <div style="height: 1rem"></div>
          <hr />
          <div style="height: 1rem"></div>
          <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-primary" @click="processChanges(true)">
              <span class="material-icons me-2">save</span>
              <span>Save Changes</span>
            </button>
          </div>
        </template>
      </div>
    </transition>
  </template>
  <template v-else>
    <transition appear name="fade" mode="out-in">
      <div class="menu px-4 py-1">
        <h4>Error fetching menu</h4>
      </div>
    </transition>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { Spinner, CSVEditor, WYSIWYG } from '@avriopolis/common/components'
import project from '@/store/project'
import Properties from '@/components/misc/Properties.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import CustomSelect from '@/components/inputs/CustomSelect.vue'
import MediaUploader from '@/components/media/MediaUploader.vue'

import axios from '@/api'
import LocalisedValue from './LocalisedValue.vue'

import { isEqual, cloneDeep } from 'lodash'
import { hasChanges, pushChange, processChanges } from '@/store/changes'

export default defineComponent({
  name: 'Menu',
  emits: ['loading'],
  components: {
    Spinner,
    Properties,
    LocalisedValue,
    TextInput,
    CustomSelect,
    MediaUploader,
    CSVEditor,
    WYSIWYG
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(true)
    const menu = ref<Menu>()
    const statuses = ref<{ text: string; value: string }[]>()
    const types = ref<{ text: string; value: string }[]>()
    const modes = ref<{ text: string; value: string }[]>()
    const categories = ref<Category[]>()
    const points_of_interest = ref<PointOfInterest[]>()
    const announcements = ref<Announcement[]>()
    const pages = ref<Component[]>()

    const newData = reactive({
      key: menu.value?.key,
      icon: menu.value?.icon,
      title: menu.value?.title,
      status: menu.value?.status,
      target_type: menu.value?.target_type,
      target_mode: menu.value?.target_mode,
      target_id: `${menu.value?.target_id}`,
      display_size: menu.value?.display_size,
      event_type: menu.value?.event_type,
      event_payload: cloneDeep(menu.value?.event_payload)
    })

    const options = computed(() => {
      switch (newData.target_type) {
        case 'poi':
          return points_of_interest.value?.filter((c) => !!c.title)
        case 'event':
          return announcements.value?.filter((c) => !!c.title)
        case 'service':
          return points_of_interest.value?.filter((c) => !!c.title)
        case 'page':
          return pages.value?.filter((c) => !!c.key)
      }
    })

    const fetchData = async () => {
      const [menuData, status, type, mode, pois, anns, components, cats] = (
        await Promise.all([
          axios.get(`/items/menus/${props.id}?fields=*`),
          axios.get(`/fields/menus/status`),
          axios.get(`/fields/menus/target_type`),
          axios.get(`/fields/menus/target_mode`),
          axios.get(`/items/points_of_interest/?fields=id,title&filter[projects_id]=${project.active?.id}`),
          axios.get(`/items/announcements/?fields=id,title&filter[projects_id]=${project.active?.id}`),
          axios.get(`/items/components/?fields=id,key,title,metadata&filter[projects_id]=${project.active?.id}`),
          axios.get(`/items/categories/?fields=id,name,description,slug,icon&filter[projects_id]=${project.active?.id}`)
        ])
      ).map((r) => r.data?.data)

      menu.value = menuData
      newData.key = menuData.key
      newData.icon = menuData.icon
      newData.title = menuData.title
      newData.status = menuData.status
      newData.target_type = menuData.target_type
      newData.target_mode = menuData.target_mode
      newData.target_id = menuData.target_id
      newData.display_size = menuData.display_size
      newData.event_type = menu.value?.event_type,
      newData.event_payload = cloneDeep(menu.value?.event_payload)
      statuses.value = status?.meta?.options?.choices
      types.value = type?.meta?.options?.choices
      modes.value = mode?.meta?.options?.choices
      points_of_interest.value = pois
      announcements.value = anns
      pages.value = components.map((c: Component) => ({ ...c, title: c.metadata.title ?? c.key }))
      categories.value = cats;

      loading.value = false
    }

    function toggleFilterCategory(categoryId: number) {
      const categories = newData.event_payload.categories ?? [];
      if (categories.includes(categoryId)) {
        categories.splice(categories.indexOf(categoryId), 1);
      } else {
        categories.push(categoryId);
      }
      update({ event_payload: { tags: newData.event_payload.tags, categories } })
    }

    async function update(data?: any) {

      console.log(menu.value, data);

      if (menu.value) {
        let allEqual = true
        for (let key of Object.keys(data)) {
          if (!isEqual((menu.value as any)[key], data[key])) {
            allEqual = false
          }
        }

        if (!allEqual) {
          pushChange('Menu', menu.value.id, data)
        } else {
          pushChange(
            'Menu',
            menu.value.id,
            Object.keys(data).reduce((p, c) => ({ [c]: undefined }), {}),
          )
        }
        
        const targetTypeChanged = (newData.target_type !== data.target_type);
        Object.assign(newData, data);

        if (targetTypeChanged && data.target_type === 'filter') {
          update({ event_type: 'filter', event_payload: {
            tags: [],
            categories: []
          } })
        }
      }
    }

    function uploaded(results: any[]) {
      console.log(results)
      update({ icon: results[0].id })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      loading,
      menu,
      statuses,
      types,
      modes,
      points_of_interest,
      newData,
      update,
      options,
      categories,
      uploaded,
      toggleFilterCategory,
      hasChanges,
      processChanges,
      mediaBase: import.meta.env.VITE_API_URL,
    }
  },
})
</script>

<style lang="scss" scoped>
.menu {
  position: relative;

  hr {
    margin: 0.5rem 0;
  }

  .hasChildren {
    opacity: 0.5;
    user-select: none;
  }

  .img-container {
    position: relative;
    min-height: 120px;
    height: 120px;
  }

  .img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      opacity: 0.25;
      filter: grayscale(1);
      pointer-events: none;
    }
  }
}
</style>
